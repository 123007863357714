import FlatList from 'flatlist-react';
import React from 'react';
import PropTypes from 'prop-types';
import stampImg from '../../assets/stamp-button.png'
import stampSoldOutImg from '../../assets/stamp-unable-button.png'
import cartImg from '../../assets/cart-icon.png'
import Button from '../Button';
import Image from '../Image';

const width = window.innerWidth;

const renderStamp = (stamp) => {
  const backgroundImg = stamp.buyable ? stampImg : stampSoldOutImg;

  const redirectPage = () => {
    if (stamp.buyable) {
      window.open(stamp.link, '_blank')
    } else if (stamp.link === 'sold') {
      alert('此郵票已售罄\nSelected Stamp Is Sold Out\n');
    }
    else {
      alert('即將發售\nRelease soon\n');
    }
  }

  const key = stamp.number ? stamp.number : stamp.title

  return (
    <Button src={backgroundImg} width={width*0.45} onClick={redirectPage} style={{marginLeft: 7}} key={key}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', paddingLeft: 10, paddingRight: 15, justifyContent: 'space-between', alignItems: 'center'}}>
        <p style={{ fontSize: 20, color: (stamp.buyable ? '#000000' : '#d8d8d8') }}>{stamp.number ? `NO.${stamp.number}` : stamp.title}</p>
        <div>
          {stamp.buyable ?
            <Image src={cartImg} width={30} /> :
            (<div >                          
              <p style={{ color: (stamp.buyable ? '#000000' : '#d8d8d8'), fontSize: 19, margin: -3}}>{stamp.link === 'sold' ? '售罄' : '即將發售'}</p>
              <p style={{ color: (stamp.buyable ? '#000000' : '#d8d8d8'), fontSize: 19, margin: 0}}>{stamp.link === 'sold' ? 'Sold' : 'Release soon'}</p>
            </div>)
          }
        </div>
      </div>  
    </Button>
  );
  }

const StampList = ({stamps}) => {
  return (
    <div>
      <FlatList
        sort={{
          by: "sort",
          descending: true,
        }}
        list={stamps}
        renderItem={renderStamp}
      />
    </div>
  )
}

StampList.propTypes = {
  stamps: PropTypes.array,
};

StampList.defaultProps = {
  stamps: [],
};

export default StampList;
