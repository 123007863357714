import React from 'react';
import styled from 'styled-components';
import Landing from './pages/Landing';
import Buy from './pages/Buy';
import backgroundImage from './assets/background-image.png'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const AppContainer = styled.div`
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: auto;
`;

function App() {
  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/index.htm" />} />
          <Route exact path='/index.htm' element={< Landing />} />
          <Route exact path='/buy.htm' element={< Buy />} />
        </Routes>
      </Router>
    </AppContainer>
  );
}

export default App;
