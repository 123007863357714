import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import Image from '../../components/Image';
import buttonImg from '../../assets/landing-page-button.png'
import bannerImg from '../../assets/banner.png'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-top: 50px;
`;

const ViewPort = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const Landing = () => {
  const navigate = useNavigate();
  const VRPageURL = 'https://metaart.connectar.group/gallery/';
  const ARPageURL = 'https://storytellar.snappopapp.com/facebook_share_pages/e4ebbbee-acaf-4040-87c5-01e2855d97f6.html';

  return (
    <ViewPort>
      <StyledHeader>
        <Image src={bannerImg} width="100%" alt="香港第二屆元宇宙藝博會" style={{ marginTop: 50 }} />
      </StyledHeader>
      <MainDiv>
        <Button src={buttonImg} onClick={()=> window.open(VRPageURL, '_blank')} width={200} height={80}>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: 18 }}>虛擬實境畫廊</p>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: -20, fontWeight: 'bold' }}>VR Gallery</p>
        </Button>

        <Button src={buttonImg} onClick={()=> window.open(ARPageURL, '_blank')} width={200} height={80}>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: 18 }}>擴增實境畫廊</p>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: -20, fontWeight: 'bold' }}>AR Gallery</p>
        </Button>

        <Button src={buttonImg} onClick={() =>  navigate('/buy.htm')} width={200} height={80}>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: 18 }}>購買郵票</p>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: -20, fontWeight: 'bold' }}>Buy</p>
        </Button>
      </MainDiv>
    </ViewPort>
  )
}
  
export default Landing;