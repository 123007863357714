import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const StyledImg = styled.img`
  object-fit: contain;
  width: ${prop('width')};
  height: ${prop('height')};
  ${ifProp('circle', css`
    border-radius: 50%;
  `)}
`;

const Image = ({ src, width, height, circle, ...props }) => {
  return (
    <StyledImg
      src={src}
      width={width}
      height={circle ? width : height}
      circle={circle}
      {...props}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  circle: PropTypes.bool,
};

Image.defaultProps = {
  src: null,
  width: undefined,
  height: undefined,
  circle: false,
};

export default Image;
