import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const StyledButton = styled.button`
  background-color: transparent;
  border: 0;
  background: url(${prop('src')}) no-repeat;
  background-size: 100% 100%;
  border: none;
  width: ${ifProp('width', prop('width'), 'auto')}px;
  height: ${ifProp('height', prop('height'), 'auto')}px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 300px;
  cursor: pointer;
`;

const Button = ({ children, src, width, height, ...props }) => {
  return (
    <StyledButton src={src} width={width} height={height} {...props}>
        {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Button.defaultProps = {
  children: null,
  src: null,
  width: undefined,
  height: undefined,
};

export default Button;
