import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import StampList from '../../components/StampList';
import Button from '../../components/Button';
import Image from '../../components/Image';
import titleImg from '../../assets/title-image.png'
import backButtonImg from '../../assets/back-icon.png'


const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 0px;
`;

const MainDiv = styled.div`
  display: flex;
  flex: 1;
  background-color: #b3b1b1;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 20px;
  padding: 10px;
`;

const ViewPort = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const stamps = [
  { number: '102', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '104', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '105', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '106', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '107', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '109', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '114', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4764' },
  { number: '115', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '116', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4766' },
  { number: '117', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '119', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '124', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4769' },
  { number: '125', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '126', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4771' },
  { number: '127', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4772' },
  { number: '129', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '132', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '134', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4774' },
  { number: '135', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4775' },
  { number: '136', title: '', sort: true, buyable: false, link: 'sold' },
  { number: '137', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4777' },
  { number: '194', title: '', sort: true, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4902' },
  { number: '', title: 'Dreams No.2', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4780' },
  { number: '', title: 'Dreams No.3', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4781' },
  { number: '', title: 'Dreams No.5', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4782' },
  { number: '', title: 'Dreams No.7', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4783' },
  { number: '', title: 'Dreams No.9', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4784' },
  { number: '', title: 'Inside Forest', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4785' },
  { number: '', title: 'The Sound of Forest-FA', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4786' },
  { number: '', title: 'The Sound of Forest-Mi', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4787' },
  { number: '', title: 'The Sound of Forest-So', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4788' },
  { number: '', title: 'Mountain - Blue Snow', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4789' },
  { number: '', title: 'Mountain - Blue Peak', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4790' },
  { number: '', title: 'Forest - Mirror', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4791' },
  { number: '', title: 'Forest - Forest in Paint', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4792' },
  { number: '', title: 'Annyeong', sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4793' },
  { number: '', title: "Kon'nichiwa", sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4794' },
  { number: '', title: "Hello", sort: false, buyable: true, link: 'https://www.whatsmall.com/cart/shopping?actionType=get&id=4795' },
];

const Buy = () => {
  const navigate = useNavigate()
  return (
    <ViewPort>
      <StyledHeader>
        <Button src={backButtonImg} width={25} height={40} onClick={()=>navigate('/index.htm')} style={{ position: 'absolute', top: 10, left: 10 }}/>
        <Image src={titleImg} width='380px' alt="香港第二屆元宇宙藝博會" style={{ marginTop: 50 }} />
        <div style={{ position: 'relative', bottom: '70px', alignItems: 'center'}}>
          <p style={{ color: '#ffffff', fontSize: 20, marginTop: -5, textAlign: 'center' }}>請選擇您想要的編號</p>
          <p style={{ color: '#ffffff', fontSize: 18, marginTop: -20, fontWeight: 'bold', textAlign: 'center' }}>Please choose the number you want</p>
        </div>
      </StyledHeader>
      <MainDiv>
        <StampList stamps={stamps} />
      </MainDiv>
    </ViewPort>
  )
}
  
export default Buy;